import styles from './MainScreen.module.scss';
import main_banner from '../../images/main_banner.webp';
import trusted_icon from '../../../src/images/trusted_icon.svg';
import location_icon from '../../../src/images/location_icon.svg';
import hierarchy_icon from '../../../src/images/hierarchy_icon.svg';
import {useEffect} from "react";
import React  from "react";

export const MainScreen = () => {

    const handleDownloadClick = React.useCallback(() => {
        window.dataLayer.push({ event: 'click_download_button_in_the_MainScreen' });
        window.open('https://click.lumosvpn.com/click', '_blank');
    }, []);

    useEffect(() => {
        window.dataLayer.push({
            event: 'pageview'
        });
    }, []);

    return (
        <main>
            <header className={styles.title_container}>
                <div>Access <span>everything</span></div>
                <div><span>securely</span> with</div>
                <div>VPN Lumos</div>
            </header>
            <div className={styles.image_wrapper}>
                <img src={main_banner} alt='main_banner' className={styles.main_banner} width='100%' height='200px'/>
            </div>
            <div className={styles.button_container}>
                <button className={styles.main_screen_button} onClick={handleDownloadClick}>Download VPN Lumos</button>
            </div>
            <div className={styles.icon_container}>
                <img src={trusted_icon} alt='trusted_icon' width={138} height={42}/>
            </div>

            <div className={styles.advantages_container}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                        <img src={location_icon} alt='location_icon' width={29} height={29}/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '6px'}}>
                        <span className={styles.span_number}>30+</span>
                        <span className={styles.span_description}>Location</span>
                    </div>
                </div>

                <div style={{height: '100%', width: '1px', background: '#AEAEAE'}}></div>

                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                        <img src={hierarchy_icon} alt='hierarchy_icon' width={26} height={26}/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '6px'}}>
                        <span className={styles.span_number}>1000+</span>
                        <span className={styles.span_description}>Servers</span>
                    </div>
                </div>
            </div>

            <div style={{textAlign:'center', marginTop: '35px', color: '#646464', fontSize: '16px'}}>
                <div>VPN Lumos-your <span style={{color: '#4F89F0'}}>ultimate</span> solution for a </div>
                <div style={{marginBottom: '20px'}}>private and secure online experience!</div>
            </div>
        </main>
    )
}